.pain_items {
    position: relative;
}

.button_active {
    background-color: #2c476c;
}

.button_active img {
    filter: brightness(0) invert(1);
}

.mapbox-popUp-container-sticky-notes {
    left: 0;
    top: 0;
    transition: ease-in-out 0.5s;
}

.sticky_note {
    width: 100%;
    height: calc(100% - 22px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
}

.sticky_note > .color-picker-popup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 180px;
    height: 30px;
    bottom: calc(100% + 5px);
    left: 0;
    background-color: #3064ab;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
    cursor: pointer;
}
.sticky_note > .pattern-picker-popup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 180px;
    height: 40px;
    bottom: calc(100% + 5px);
    left: 0;
    background-color: #3064ab;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
    cursor: pointer;
}

.painter_tools_path_data_item {
    height: 28px;
    width: 28px;
}

.color-picker-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.comment-box-popup-container_color-picker > .current-picked-color-comment-box {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.comment-box-popup-container_color-picker > .current-picked-color-comment-box > svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.painter_tools_color_data_item > .available-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.sticky_note > .sticky_note_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 216px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    bottom: 270px;
    right: -10px;
    box-shadow: 0 4px 24px 0 #292E3233;
}

.comment_box_tools_color_item {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.comment_box_tools_color_item:hover {
    border: 2px solid #fff;
}

.sticky_note > .sticky_note_tools_color_item_container > span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 89%;
    top: 98%;
    background: #fff;
}

.sticky_note > .edit-popup-header {
    width: 100%;
}

.sticky_note > .edit-popup-header > .popUp-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    border-radius: 8px;
    background-color: #3064ab;
}

.sticky_note > .sticky-note-editor > .popUp-counter {
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sticky_note > .sticky-note-editor > .popUp-counter > span {
    padding-right: 5px;
}

.sticky_textarea {
    width: 100%;
    font-family: "Noto Sans Armenian", "sans-serif";
    height: 100%;
    line-height: 1.15rem;
    font-size: 1rem;
    border: none;
    outline: none;
    resize: none;
    padding: 0 8px 8px;
}
.sticky-note-editor {
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 3px;
    padding-top: 20px;
}

.sticky-note-editor::before,
.sticky-note-editor::after {
    content: "";
    position: absolute;
    display: block;
}

.sticky-note-editor::after {
    position: absolute;
    height: 24px;
    width: calc(100% - 23px);
    bottom: -23px;
    right: 0;
}

textarea::-webkit-resizer {
    display: none;
}

textarea::-webkit-scrollbar {
    max-width: 3px;
}

textarea::-webkit-scrollbar-thumb {
    background-color: #2c476c;
    outline: none;
    border-radius: 20px;
}

.popUp-actions span {
    width: 26px;
    cursor: pointer;
    margin: 0px 5px;
}

.popUp-save > img {
    opacity: .5;
    cursor: default;
}

.popUp-save .active-save-icon {
    opacity: 1;
    cursor: pointer;
}

.sticky-notes-toolbox-marker {
    display: block;
    width: 40px;
    height: 40px;
    z-index: 9999;
}

.mapbox-popUp-container-sticky-notes > .mapboxgl-popup-tip {
    display: none !important;
}

.sticky-notes-toolbox-marker.mapboxgl-marker:hover,
.sticky-notes-toolbox-marker.mapboxgl-marker:active,
.mapboxgl-popup
{
    z-index: 9999;
}

.sticky-notes-toolbox-marker > svg {
    height: 40px;
    width: 40px;
}

.hover-popUp-container {
    position: absolute;
    bottom: 45px;
    left: 36px;
    max-width: 320px;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(-15px);
}

.hover-popUp-container.notHovered {
    display: block;
    opacity: 0;
    transform: translateY(0px);
    transition: all .7s ease-in-out;
    animation: reverseAnim .7s ease 0s 1 normal forwards;
}

.hover-popUp-container.hovered {
    display: block;
    opacity: 1;
    transform: translateY(0px);
    transition: all .7s ease-in-out;
    animation: myAnim .7s ease 0s 1 normal forwards;
}

@keyframes myAnim {
    0% {
        display: block;
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes reverseAnim {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-15px);
        display: none;
    }
}

.hover-popUp-container > p {
    width: 100%;
}

.hover-popUp-container > .hover-popUp-text {
    /*max-height: 145px;*/
    font-family: 'Noto Sans Armenian', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: #6E6B7B;
    border-radius: 30px 30px 30px 0px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #292E3233;
    word-break: break-word;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    /*max-height: 145px; !* I needed this to get it to work *!*/
    overflow: hidden;
    width: max-content;
    max-width: 300px;
}

.background-color-changer-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    cursor: pointer;
}

.rotate-handle-holder .rotate-handle {
    width: 10px;
    height: 10px;
    top: -15px;
    left: -8px;
    border: 3px solid #559CFD;
    border-radius: 100%;
    cursor: url('/src/assets/imgs/PaintBar/rotate.svg'), auto;
}

.rotate-handle-holder {
    border: none;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 100%;
    margin-left: 100%;
}

.color-picker-comment-box,
.background-color-changer-icon,
.popUp-delete,
.popUp-close {
    z-index: 999;
}

.react-resizable-handle {
    position: absolute;
    width: 25px !important;
    height: 25px !important;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-position: bottom right;
    padding: 0 !important;
}

.react-resizable-handle-se {
    cursor: url('/src/assets/imgs/PaintBar/resize.svg'), auto !important;
}

.rotate-zero {
    transform: rotate(0deg) !important;
}

.markerIcon {
    position: absolute;
    z-index: 1001;
    top: -25px;
    left: calc(50% - 10px)
}

.markerIconOnly {
    position: absolute;
    z-index: 1001;
    top: -25px;
    left: calc(50% - 10px)
}

.sticky_note_container {
    position: absolute;
    z-index: 9999;
}

